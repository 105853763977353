import React from 'react'

import Layout from '../components/layout'
//import Image from '../components/image'
import { Helmet } from "react-helmet"

const Omnie = () => (
  <Layout>
    
    <Helmet title={'O mnie | miszczuk.org'} />

    <article className="uk-article" >

      <div className="uk-card uk-card-default uk-card-hover uk-margin">
        <div className="uk-card-body ">
          <h2 className="uk-card-title">o mnie</h2>
          <p>Narzędzia, których używam codziennie w swojej pracy to HTML, CSS/SASS, JavaScript i Git, ale mam doświadczenie z PHP, Laravel czy SQL. Moim obecnym celem jest wzmocnienie podstaw programowania oraz pogłębienie wiedzy z zakresu JavaScript (w szczególności Vanilla i ReactJS), a także tworzenie bardziej osobistych projektów dla zabawy z zachowaniem znajomości z całego front i backendu. Obecnie zafacynował mnie również ekosystem JAMstack z GatsbyJS na czele.</p>
        </div>
      </div>
    </article>


    
  </Layout>
)

export default Omnie
