import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

import Header from './header'
import Footer from './footer';

import './layout.css';



const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}

    render={data => (
      <div className="uk-section-muted">
        <div className="">
        
          <Header siteTitle={data.site.siteMetadata.title} />

          <div className="uk-section uk-section-muted">
            <div className="uk-container">
              <div className="uk-width-1-1 uk-width-3-4@m uk-align-center ">

  {children}

              </div>
            </div>
          </div>

        </div>

        <Footer />
      </div>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
