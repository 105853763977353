import React from 'react'
import style from './footer.module.css'


const Footer = () => (
  <footer className={style.foot} >

    <p>{new Date().getFullYear()} © Rafał Miszczuk </p>
    

  </footer>
)


export default Footer